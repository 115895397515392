/*
	1.该文件是用于创建一个为Count组件服务的reducer，reducer的本质就是一个函数
	2.reducer函数会接到两个参数，分别为：之前的状态(preState)，动作对象(action)
*/
import {SETROOMINFO, UPROOMINFO} from '../constant'

const initState = {
    room_id: undefined,

    parent_organization: undefined,
    organization_name: undefined,
    organization_type: '组织',

    house_type: undefined,
    building_area: undefined,
    internal_area: undefined,
    property_type: '福利房',

    // 福利房
    house_certificate_type: undefined,
    house_ownership: undefined,
    house_payment_amount: undefined,
    house_payment_time: undefined,

    // 周转房
    turnover_house_residence_type: undefined,
    turnover_house_lease_time: undefined,
    turnover_house_rent: undefined,

    // 博士后公寓
    postdoctoral_apartment_residence_type: undefined,
    postdoctoral_apartment_entry_time: undefined,
    postdoctoral_apartment_exit_time: undefined,
    postdoctoral_apartment_rent: undefined,

    // 人才引进公寓
    talent_apartment_residence_type: undefined,
    talent_apartment_lease_time: undefined,
    talent_apartment_rent: undefined,

    // 租凭房
    rental_house_residence_type: undefined,
    rental_house_management_style: undefined,
    rental_house_lease_time: undefined,
    rental_house_rent: undefined,

    // 其他
    other_residence_type: undefined,
    other_lease_time: undefined,
    other_rent: undefined,

    // 户主
    personnel_number: undefined,
    head_of_household: undefined,
    head_id_number: undefined,
    phone_number: undefined,
    work_unit: undefined,
    residential_address: undefined,
    head_spouse: undefined,
    spouse_id_number: undefined,
    spouse_phone_number: undefined,
    spouse_work_unit: undefined,
    spouse_residential_address: undefined,

    // 现住户或户主
    current_resident_head_household_name: undefined,
    current_resident_head_household_phone_number: undefined,
    current_resident_head_household_work_unit: undefined,
    current_resident_head_household_relationship: undefined,

    // 备注
    remarks: undefined,

    is_data: 1,
} //初始化状态

export default function countReducer(preState = initState, action) {
    //从action对象中获取：type、data
    const {type, data} = action;
    //根据type决定如何加工数据
    switch (type) {
        case UPROOMINFO:
            return {...preState, ...data};
        case SETROOMINFO:
            return {...data};
        default:
            return preState;
    }
}