import React from "react";
import { useRoutes } from "react-router-dom";
import routes from "./routes";

// antd组件全局配置
import zhCN from 'antd/locale/zh_CN';
import {ConfigProvider} from "antd";


export default function App() {
    const element = useRoutes(routes);

    return (
        <>
            <ConfigProvider
                locale={zhCN}
                ConfigProvider
                theme={{
                    components: {
                        Radio: {
                            wrapperMarginInlineEnd: 50
                        },
                    },
                }}
                // theme={{
                //     components: {
                //         Radio: {
                //             buttonPaddingInline: 50,
                //             buttonBg: 'red',
                //         },
                //     },
                // }}
            >
                {element}
            </ConfigProvider>
        </>
    )
}